import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import { PageProps } from "../helpers/props"

const NotFoundPage: React.FunctionComponent<PageProps> = ({ location }) => {
  return (
    <Layout>
      <Meta pathname={location.pathname} title="404 Not Found" />
      <Navbar isFixedTop={false} />
      <section className="section" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className="title is-1">404</h1>
              <h2 className="title is-2">Pagina non trovata</h2>
              <p>
                <Link to="/">Clicca per tornare alla pagina principale.</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
